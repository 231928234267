var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3"},[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',{staticClass:"text-normal"},[_c('b-row',[_c('b-col',{staticClass:"pl-0 pr-0 pr-md-1",attrs:{"sm":"4"}},[_c('div',{staticClass:"header-secondary title-panel header-title"},[_c('span',[_vm._v("Questions")]),_c('b-button',{staticClass:"add-btn",on:{"click":_vm.addQuestion}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus-square"}}),_c('span',[_vm._v("Create New")])],1)],1)]),_c('b-col',{staticClass:"pr-0 pl-0 pl-md-1",attrs:{"sm":"8"}},[_c('div',{staticClass:"header-secondary title-panel header-title d-none d-sm-block"},[_c('span',[_vm._v("Detail")])])])],1),_c('b-row',{staticClass:"bg-grey"},[_c('b-col',{staticClass:"pl-0 pr-0 pr-md-1",attrs:{"sm":"4"}},[_c('div',{staticClass:"p-2 question-panel shadow-sm overflow-auto"},[_c('draggable',{attrs:{"list":_vm.questionList},on:{"end":_vm.changeDrag}},_vm._l((_vm.questionList),function(ques,index){return _c('div',{key:index,class:`${
                _vm.questionActive == index ? 'active ' : ''
              }mt-2 p-3 question-card pointer`,on:{"click":function($event){_vm.questionActive = index}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-2 text-black font-weight-bold"},[_vm._v("Question "+_vm._s(index + 1)+":")]),_c('div',{staticClass:"mr-2 type-box"},[_vm._v(" "+_vm._s(_vm.questionTypeText(ques.field_type_id))+" ")]),_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(ques.name))])]),_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"mb-1 text-light-grey text-truncate"},[_vm._v(" "+_vm._s(_vm.questionDetailText(ques))+" ")])])])}),0)],1)]),_c('b-col',{staticClass:"pr-0 pl-1",attrs:{"sm":"8"}},[_c('div',{staticClass:"header-secondary title-panel d-block d-sm-none"},[_c('span',[_vm._v("Detail")])]),_c('div',{staticClass:"pt-2 bg-white text-normal shadow-sm"},[(_vm.questionList[_vm.questionActive].field_type_id == 0)?_c('div',_vm._l((_vm.questionList[_vm.questionActive].profile),function(profile,index){return _c('b-row',{key:index,staticClass:"px-3"},[_c('b-col',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"sm":"2"}},[_c('font-awesome-icon',{class:`icon-size ${index == 0 ? 'disabled' : 'pointer'}`,attrs:{"icon":"chevron-circle-up"},on:{"click":function($event){return _vm.switchProfileIndex(index, 'up')}}}),_vm._v(" "+_vm._s(index + 1)+" "),_c('font-awesome-icon',{class:`icon-size ${
                    index == _vm.questionList[_vm.questionActive].profile.length - 1
                      ? 'disabled'
                      : 'pointer'
                  }`,attrs:{"icon":"chevron-circle-down"},on:{"click":function($event){return _vm.switchProfileIndex(index, 'down')}}}),_c('b-form-checkbox',{attrs:{"id":`checkbox-profile-${index}`,"name":`checkbox-profile-${index}`,"disabled":profile.is_profile_required},model:{value:(profile.avairable),callback:function ($$v) {_vm.$set(profile, "avairable", $$v)},expression:"profile.avairable"}})],1),_c('b-col',{attrs:{"sm":"5"}},[(!_vm.isLoading)?_c('InputText',{staticClass:"margin-input",attrs:{"textFloat":"","value":_vm.questionProfileTypeText(profile.field_profile_type_id),"disabled":"","placeholder":_vm.questionProfileTypeText(profile.field_profile_type_id),"type":"text"}}):_vm._e()],1),_c('b-col',{attrs:{"sm":"5"}},[(!_vm.isLoading)?_c('InputText',{staticClass:"margin-input",attrs:{"textFloat":"","placeholder":profile.name,"type":"text"},model:{value:(profile.name),callback:function ($$v) {_vm.$set(profile, "name", $$v)},expression:"profile.name"}}):_vm._e()],1)],1)}),1):_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('b-form-checkbox',{attrs:{"checked":_vm.questionList[_vm.questionActive].is_show,"value":1,"unchecked-value":0},on:{"change":function($event){$event == 0
                      ? (_vm.questionList[_vm.questionActive].required = false)
                      : undefined}},model:{value:(_vm.questionList[_vm.questionActive].is_show),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive], "is_show", $$v)},expression:"questionList[questionActive].is_show"}},[_vm._v("Show on Register Form")])],1),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v("Required")]),_c('b-form-checkbox',{staticClass:"mb-1 mr-2",attrs:{"size":"lg","disabled":_vm.typeHidden(_vm.questionList[_vm.questionActive].field_type_id) ||
                    !_vm.questionList[_vm.questionActive].is_show,"switch":""},model:{value:(_vm.questionList[_vm.questionActive].required),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive], "required", $$v)},expression:"questionList[questionActive].required"}}),_c('b-icon',{staticClass:"icon-size pointer",attrs:{"size":"lg","icon":"trash-fill"},on:{"click":_vm.deleteQuestion}})],1)]),_c('div',{staticClass:"mt-3 w-25"},[_c('b-form-select',{attrs:{"options":_vm.fieldTypes,"value-field":"id","text-field":"name"},on:{"change":_vm.changeQeustionType},model:{value:(_vm.questionList[_vm.questionActive].field_type_id),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive], "field_type_id", $$v)},expression:"questionList[questionActive].field_type_id"}})],1),_c('div',{staticClass:"mt-3"},[(
                  !(
                    _vm.questionList[_vm.questionActive].field_type_id == 10 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 11 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 13 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 14
                  )
                )?_c('div',[_c('InputText',{staticClass:"text-float-normal w-50",attrs:{"textFloat":"Validate Text","placeholder":_vm.questionList[_vm.questionActive].validate_text,"disabled":_vm.questionList[_vm.questionActive].field_type_id == 10 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 11 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 13 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 14,"type":"text"},model:{value:(_vm.questionList[_vm.questionActive].validate_text),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive], "validate_text", $$v)},expression:"questionList[questionActive].validate_text"}})],1):_vm._e(),(
                  !(
                    _vm.questionList[_vm.questionActive].field_type_id == 9 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 13 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 14
                  )
                )?_c('div',[_c('InputText',{class:[
                    'text-float-normal question-name-input margin-input-5',
                    _vm.isSubmit && !_vm.questionList[_vm.questionActive].name
                      ? 'error'
                      : '',
                  ],attrs:{"isRequired":"","textFloat":"Question","disabled":_vm.questionList[_vm.questionActive].field_type_id == 9 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 13 ||
                    _vm.questionList[_vm.questionActive].field_type_id == 14,"placeholder":_vm.questionList[_vm.questionActive].name,"type":"text"},model:{value:(_vm.questionList[_vm.questionActive].name),callback:function ($$v) {_vm.$set(_vm.questionList[_vm.questionActive], "name", $$v)},expression:"questionList[questionActive].name"}}),(_vm.isSubmit && !_vm.questionList[_vm.questionActive].name)?_c('div',[_c('span',{staticClass:"text-danger"},[_vm._v("Please input text.")])]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"my-3"},[(_vm.questionList[_vm.questionActive].field_type_id == 1)?_c('FieldTextDetail',{attrs:{"questionList":_vm.questionList,"questionActive":_vm.questionActive}}):_vm._e(),(
                  _vm.questionList[_vm.questionActive].field_type_id >= 2 &&
                  _vm.questionList[_vm.questionActive].field_type_id <= 4
                )?_c('FieldChoiceDetail',{attrs:{"questionList":_vm.questionList,"questionActive":_vm.questionActive}}):_vm._e(),(_vm.questionList[_vm.questionActive].field_type_id == 5)?_c('DateInput'):_vm._e(),(
                  _vm.questionList[_vm.questionActive].branchList &&
                  _vm.questionList[_vm.questionActive].branchList.length > 0 &&
                  _vm.questionList[_vm.questionActive].field_type_id == 6
                )?_c('ModalBranchForm',{ref:"BranchModal",attrs:{"list":_vm.questionList[_vm.questionActive].branchList,"field":_vm.questionList[_vm.questionActive],"id":_vm.id},on:{"selectBranch":_vm.selectBranch}}):_vm._e(),(_vm.questionList[_vm.questionActive].field_type_id == 7)?_c('Date',{}):_vm._e(),(_vm.questionList[_vm.questionActive].field_type_id == 12)?_c('InputTextArea',{staticClass:"mt-3 custom-input",attrs:{"textFloat":"","type":"text","placeholder":"","rows":"3"}}):_vm._e()],1)])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }