<template>
  <b-row class="mt-3">
    <b-col cols="6" class="mt-3 pr-1">
      <!-- <datetime
        type="date"
        format="dd/MM/yyyy"
        value-zone="local"
        class="date-picker"
        placeholder="DD/MM/YYYY"
      >
      </datetime> -->
      <div class="input-container">
        <datetime
          type="date"
          class="border-none"
          placeholder="DD/MM/YYYY"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          :input-style="{ border: 'none' }"
          ref="DateInput"
        >
        </datetime>
        <div
          class="icon-container text-right"
          @click="$refs.DateInput.isOpen = true"
        >
          <font-awesome-icon
            icon="calendar-alt"
            class="pointer color-primary"
            color="#7160C4"
          />
        </div>
      </div>
    </b-col>
    <b-col sm="6" class="mt-3 pl-1">
      <!-- <datetime
        type="time"
        format="HH:mm"
        value-zone="local"
        class="date-picker"
        placeholder="HH:MM"
      >
      </datetime> -->
      <div class="input-container">
        <datetime
          type="time"
          placeholder="HH:MM"
          format="HH:mm"
          value-zone="Asia/Bangkok"
          :input-style="{ border: 'none' }"
          ref="TimeInput"
        >
        </datetime>
        <div
          class="icon-container text-right"
          @click="$refs.TimeInput.isOpen = true"
        >
          <font-awesome-icon
            icon="calendar-alt"
            class="pointer color-primary"
            color="#7160C4"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DateInput",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.r-l-5 {
  padding-right: 5px;
  padding-left: 5px;
}
::v-deep .date-picker .vdatetime-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-container {
  display: flex;
  padding: 0.4rem;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
}
</style>
