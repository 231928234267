<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div v-if="!isPreview">
        <!-- <b-row class="tab-title">
                    <b-col>คำถาม</b-col>
                    <b-col class="text-right base-button red-font">
                        <b-button
                            class="btn-add-question"
                            v-if="!isPreview"
                            :disabled="isRegister > 0 ? true : false"
                            @click="addField()"
                        >+ <span class="title-underline">เพิ่มคำถาม</span></b-button>
                    </b-col>
                </b-row> -->
        <b-card title header-tag="header" footer-tag="footer">
          <!-- <b-row class="pb-3">
                        <b-col class="d-flex justify-content-end">
                            <b-button class="base-button btn-height" @click="showPreview()">
                                <b-icon
                                    class="mr-2"
                                    font-scale="1.5"
                                    icon="eye-fill"
                                ></b-icon>
                                <b>Preview</b>
                            </b-button>
                        </b-col>
                    </b-row> -->
          <div
            v-if="!isSaving"
            id="content"
            ref="container"
            class="scroll-form"
          >
            <div v-for="(field, i) in fields" :key="i">
              <b-card
                v-show="field.field_profile_type_id != 19"
                :class="field.isDuplicate ? 'card-duplicate-error' : ''"
              >
                <b-card-text>
                  <!-- <b-row>
                                    <b-col>
                                        <b-form-group label-cols-sm="4" label="ประเภทคำถาม:">
                                            <b-form-radio-group 
                                                class="pt-2" 
                                                id="radio-group-2" 
                                                name="radio-sub-component"
                                                v-model="isProfile"
                                            >
                                                <b-form-radio value="true">ข้อมูลลูกค้า</b-form-radio>
                                                <b-form-radio value="false">คำถามอื่นๆ</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-row>
                                            <b-col class="text-right">
                                                <b-form-checkbox
                                                    class="mt-2"
                                                    v-model="field.required"
                                                    name="check-button"
                                                    :disabled="chkDisabled(field,i)"
                                                    switch
                                                >
                                                    จำเป็นต้องกรอก
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col>
                                                <b-row>
                                                    <b-col class="icon-size text-right"><font-awesome-icon icon="chevron-circle-up" /></b-col>
                                                    <b-col><b-form-input
                                                        v-model="field.sortField"
                                                        placeholder="Sort"
                                                        type="number"
                                                    ></b-form-input></b-col>
                                                    <b-col class="icon-size"><font-awesome-icon icon="chevron-circle-down"/></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row v-if="isProfile">

                                </b-row> -->
                  <!-- Flied -->
                  <b-row>
                    <b-col cols="9">
                      <b-form-input
                        v-model="field.name"
                        placeholder="Question"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select
                        style="-webkit-appearance: none; -moz-appearance: none"
                        v-model="field.field_type_id"
                        :options="fieldTypes"
                        @change="changeTypeForm(i)"
                        value-field="id"
                        text-field="name"
                        :disabled="chkDisabled(field, i)"
                      ></b-form-select>
                    </b-col>
                  </b-row>
                  <!-- Condition -->
                  <b-row
                    class="mt-2"
                    v-if="field.field_type_id == 1 && !isBufferLoading"
                  >
                    <b-col cols="5">
                      <b-form-select
                        style="-webkit-appearance: none; -moz-appearance: none"
                        v-model="bufferConditionSelects[i][0]"
                        :options="showCondition"
                        :disabled="chkDisabled(field, i)"
                        @input="addValueInFirstCondition(i)"
                      ></b-form-select>
                    </b-col>
                    <b-col cols="4">
                      <div
                        v-if="
                          bufferConditionSelects[i][0] == 1 &&
                          bufferConditionSelects[i][1] != 1 &&
                          bufferConditionSelects[i][1] != 2
                        "
                      >
                        <b-form-select
                          style="
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "
                          v-model="bufferConditionSelects[i][1]"
                          :options="showSubCondition"
                          @input="addValueInSetCondition(i)"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                      <div
                        v-if="
                          bufferConditionSelects[i][1] == 1 &&
                          bufferConditions[i][1]
                        "
                      >
                        <!-- length -->
                        <b-form-input
                          type="number"
                          placeholder="length"
                          v-model="bufferConditions[i][1].value"
                          :disabled="chkDisabled(field, i)"
                          @input="
                            addBufferConditionLength(
                              i,
                              bufferConditions[i][2].value,
                              field
                            )
                          "
                        ></b-form-input>
                      </div>
                      <!-- min - max -->
                      <div
                        v-if="
                          bufferConditionSelects[i][1] == 2 &&
                          bufferConditions[i][1]
                        "
                      >
                        <b-row>
                          <b-col>
                            <b-form-input
                              type="number"
                              placeholder="min"
                              v-model="bufferConditions[i][1].value"
                              @input="
                                addBufferConditionMin(
                                  i,
                                  bufferConditions[i][2].value,
                                  field
                                )
                              "
                              :disabled="chkDisabled(field, i)"
                            ></b-form-input>
                          </b-col>
                          <b-col cols="2" class="text-center">to</b-col>
                          <b-col>
                            <b-form-input
                              type="number"
                              placeholder="max"
                              v-model="bufferConditions[i][2].value"
                              @input="
                                addBufferConditionMax(
                                  i,
                                  bufferConditions[i][2].value,
                                  field
                                )
                              "
                              :min="parseInt(bufferConditions[i][1].value) + 2"
                              :disabled="chkDisabled(field, i)"
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col cols="3">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Checkbox -->
                  <b-row v-if="field.field_type_id == 2" class="relative">
                    <b-col
                      cols="9"
                      v-for="(choice, index) in field.field_choices"
                      :key="index"
                    >
                      <b-table-simple class="mt-2">
                        <b-tbody>
                          <b-tr>
                            <b-td colspan="2">
                              <b-row>
                                <b-col>
                                  <b-form-checkbox></b-form-checkbox>
                                </b-col>
                                <b-col cols="10">
                                  <b-form-input
                                    v-model="choice.name"
                                    :disabled="chkDisabled(field, i)"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-td>
                            <b-td class="text-right">
                              <h3>
                                <b-icon
                                  class="mt-2"
                                  @click="removeChoice(i, index)"
                                  size="lg"
                                  icon="x"
                                ></b-icon>
                              </h3>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                    <b-col cols="9" class="mt-3">
                      <b-form-input
                        v-model="bufferInputs[i]"
                        placeholder="+ Add"
                        :disabled="chkDisabled(field, i)"
                        @keydown.enter="addChoice(i)"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3" class="mt-2 position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Multiple -->
                  <b-row class="mt-3" v-if="field.field_type_id == 3">
                    <b-col cols="4">
                      <b-form-select
                        style="-webkit-appearance: none; -moz-appearance: none"
                        v-model="bufferMultipleChoice[i].selected"
                        :options="subMultipleChoice"
                        @change="setchoiceInMuit(i)"
                      ></b-form-select>
                    </b-col>
                    <b-col
                      cols="9"
                      v-for="(field, index) in field.field_choices"
                      :key="index"
                    >
                      <b-table-simple class="mt-2" v-if="field.name">
                        <b-tbody>
                          <b-tr>
                            <b-td colspan="2">
                              <b-row>
                                <b-col>
                                  <b-form-radio></b-form-radio>
                                </b-col>
                                <b-col cols="10">
                                  <b-form-input
                                    class="italic-font"
                                    v-model="field.name"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-td>
                            <b-td class="text-right">
                              <h3>
                                <b-icon
                                  class="mt-2"
                                  @click="removeChoice(i, index)"
                                  size="lg"
                                  icon="x"
                                ></b-icon>
                              </h3>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                    <b-col cols="9" class="mt-3">
                      <b-form-input
                        v-model="bufferInputs[i]"
                        placeholder="+ Add"
                        :disabled="chkDisabled(field, i)"
                        @keydown.enter="addChoice(i)"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3" class="position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Dropdown -->
                  <b-row v-if="field.field_type_id == 4">
                    <b-col
                      cols="9"
                      v-for="(choice, index) in field.field_choices"
                      :key="index"
                    >
                      <!-- <b-table-simple
                        class="mt-2"
                        v-if="choice.name && choice.name !== 'ทั้งหมด'"
                      > -->
                      <b-table-simple
                        class="mt-2"
                        v-if="choice.name"
                      >
                        <b-tbody>
                          <b-tr>
                            <b-td>
                              <b-form-input
                                v-model="choice.name"
                                :disabled="chkDisabled(field, i)"
                              ></b-form-input>
                            </b-td>
                            <b-td class="text-right">
                              <h3>
                                <b-icon
                                  class="mt-2"
                                  @click="removeChoice(i, index)"
                                  size="lg"
                                  icon="x"
                                ></b-icon>
                              </h3>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                    <b-col cols="9" class="mt-3">
                      <b-form-input
                        v-model="bufferInputs[i]"
                        placeholder="+ Add"
                        :disabled="chkDisabled(field, i)"
                        @keydown.enter="addChoice(i)"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="3" class="mt-2 position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!--Date > Datetime -->
                  <b-row v-if="field.field_type_id == 5">
                    <b-col
                      class="mt-2"
                      v-for="(choice, index) in field.field_choices"
                      :key="index"
                    >
                      <DateInput />
                    </b-col>
                    <b-col cols="3" class="mt-2 position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!--Branch Dropdown -->
                  <b-row v-if="field.field_type_id == 6">
                    <ModalBranchForm
                      v-if="field.branchList && field.branchList.length > 0"
                      :list="field.branchList"
                      @selectBranch="selectBranch"
                      :field="field"
                      :id="id"
                    />
                    <b-col cols="3" class="mt-2 position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Date -->
                  <b-row v-if="field.field_type_id == 7">
                    <b-col
                      class="mt-2"
                      v-for="(choice, index) in field.field_choices"
                      :key="index"
                    >
                      <Date />
                    </b-col>
                    <b-col cols="3" class="mt-2 position-checkbox">
                      <b-form-checkbox
                        class=""
                        v-if="isChangeField"
                        :disabled="chkDisabled(field, i)"
                        v-model="field.isProfile"
                        :key="i"
                        :value="true"
                        @change="typeProfile($event, i)"
                        :checked="field.isProfile"
                      >
                        <label class="title-fields">PROFILE</label>
                      </b-form-checkbox>
                      <div v-if="field.isProfile">
                        <b-form-select
                          v-model="field.field_profile_type_id"
                          :options="profileType"
                          class=""
                          text-field="name"
                          value-field="id"
                          :disabled="chkDisabled(field, i)"
                        ></b-form-select>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="field.field_type_id == 8">
                    <b-col>
                      <MultipleImages
                        class="pt-4"
                        @updateImageList="updateImageList"
                        :dataList="form.imageList"
                        @deleteImagesList="deleteImgList"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="field.field_type_id == 12">
                    <b-col sm="9">
                      <InputTextArea
                        textFloat=""
                        type="text"
                        class="my-0 custom-input"
                        :placeholder="field.name"
                        rows="3"
                      />
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-row class="mb-2">
                  <b-col cols="3">
                    <div>
                      <label class="title-fields">Sort Fields</label>
                      <b-form-input
                        v-model="field.sortField"
                        placeholder="Sort"
                        type="number"
                      ></b-form-input>
                    </div>
                    <div class="mt-3">
                      <b-form-checkbox
                        class=""
                        :disabled="chkDisabled(field, i)"
                        v-model="field.is_show"
                        :key="i"
                        :checked="field.is_show"
                        :value="1"
                        :unchecked-value="0"
                      >
                      </b-form-checkbox
                      ><label class="title-fields">Customer Avairable</label>
                    </div>
                    <div>
                      <b-form-checkbox
                        class="mt-2"
                        v-model="field.required"
                        name="check-button"
                        :disabled="chkDisabled(field, i)"
                        switch
                      >
                        Require
                      </b-form-checkbox>
                    </div>
                  </b-col>
                  <b-col
                    class="text-right"
                    align-self="center"
                    v-if="isRegister === 0"
                  >
                    <div class="">
                      <b-button
                        v-if="!chkDisabled(field, i)"
                        variant="light"
                        class=""
                        :disabled="chkDisabled(field, i)"
                        @click="removeForm(i)"
                      >
                        <b-icon size="lg" icon="trash-fill" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
        </b-card>
      </div>
      <div id="footer" class="d-flex align-items-center">
        <b-row class="w-100">
          <b-col>
            <b-button
              v-if="!isPreview"
              :disabled="isRegister > 0 ? true : false"
              @click="addField()"
              class="base-button red-font"
              size="lg"
            >
              <div class="d-flex flex-row">
                <b-icon class="mr-2" size="lg" icon="plus-circle"></b-icon>
                <div>Add Question</div>
              </div>
            </b-button>
          </b-col>
          <b-col class="text-right" style="padding-right: 0px">
            <b-button
              v-if="isPreview"
              :disabled="isRegister > 0 ? true : false"
              @click="back()"
              class="base-button red-font width"
              size="lg"
              >Back
            </b-button>
            <b-button
              v-if="!isPreview"
              @click="createdForm"
              class="pink-whitebutton width"
              size="lg"
              >Save
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Date from "@/components/form/preview/Date";
import Preview from "@/components/form/preview/Preview";
import DateInput from "@/components/form/preview/DateInput";
import ModalBranchForm from "@/components/form/detail/ModalBranchForm";
import OtherLoading from "@/components/loading/OtherLoading";
import MultipleImages from "@/components/inputs/MultipleImages";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    Preview,
    DateInput,
    ModalBranchForm,
    OtherLoading,
    Date,
    MultipleImages,
    InputTextArea,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    deleteImageList: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
    isBufferLoading: {
      required: true,
      type: Boolean,
    },
    showCondition: {
      required: true,
      type: Array,
    },
    bufferConditionSelects: {
      required: true,
      type: Array,
    },
    bufferConditions: {
      required: true,
      type: Array,
    },
    bufferInputs: {
      required: true,
      type: Array,
    },
    bufferMultipleChoice: {
      required: true,
      type: Array,
    },
    profileType: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    subMultipleChoice: {
      required: true,
      type: Array,
    },
    showSubCondition: {
      required: true,
      type: Array,
    },
    firstFields: {
      required: true,
      type: Array,
    },
    isRegister: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      previewForm: [],
      isPreview: false,
      status: "",
      selectProfile: null,
      branchList: [],
      isLoading: true,
      isSaving: false,
      isChangeField: true,
      isProfile: true,
    };
  },
  async created() {
    for (const field of this.fields) {
      if (field.field_profile_type_id && field.field_profile_type_id !== 0) {
        field.isProfile = true;
        if (field.required) {
          field.is_show = 1;
        }
      } else {
        field.isProfile = false;
      }
      if (field.field_type_id === 6) {
        this.isLoading = true;
        await this.getBranchList(field, field.branch_group_id);
      }
    }
    let result = this.fields.some((e) => e.field_type_id === 6);
    if (!result) {
      this.isLoading = false;
    }
  },
  methods: {
    typeProfile(evt, index) {
      this.isChangeField = false;
      if (evt) {
        this.fields[index].isProfile = true;
      } else {
        this.fields[index].isProfile = false;
        this.fields[index].field_profile_type_id = 0;
      }
      this.isChangeField = true;
    },
    selectBranch(value, id) {
      if (id === 0) {
        this.fields[this.fields.length - 1].select_branch = value;
      } else {
        for (let i in this.fields) {
          if (this.fields[i].id === id) {
            this.fields[i].select_branch = value;
          }
        }
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
      this.isLoading = false;
      if (id === 0) {
        this.fields[this.fields.length - 1].select_branch = [1];
      }
    },
    createdForm() {
      this.isSaving = true;
      for (let i = 0; i < this.fields.length - 1; i++) {
        for (let j = i + 1; j < this.fields.length; j++) {
          if (this.fields[i].field_profile_type_id > 0) {
            if (
              this.fields[i].field_profile_type_id ==
              this.fields[j].field_profile_type_id
            ) {
              this.fields[j].isDuplicate = true;
              // return true;
            }
          }
        }
      }
      // return false;
      const found = this.fields.find((element) => element.isDuplicate == true);
      if (!found) {
        this.fields.sort((a, b) =>
          parseInt(a.sortField) > parseInt(b.sortField)
            ? 1
            : parseInt(b.sortField) > parseInt(a.sortField)
            ? -1
            : 0
        );
        for (const field of this.fields) {
          delete field.isDuplicate;
        }
        this.$emit("createdForm", this.branchList);
      }
      this.isSaving = false;
    },
    addField() {
      this.fields.push({
        field_type_id: 1,
        required: false,
        img: "",
        name: "",
        is_show: 1,
        field_profile_type_id: 0,
        field_add_new: true,
        select_branch: [],
        field_choices: [],
        field_conditions: [
          { field_condition_type_id: 1, value: "" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ],
      });
      this.bufferInputs.push("");
      this.bufferConditions.push([
        { field_condition_type_id: 1, value: "" },
        { field_condition_type_id: 2, value: "" },
        { field_condition_type_id: 3, value: "" },
        { field_condition_type_id: 4, value: "" },
      ]);
      this.bufferMultipleChoice.push({ selected: 0 });
      this.bufferConditionSelects.push([0, 0]);
      this.scrollToEnd();
    },
    scrollToEnd() {
      let content = this.$refs.container;
      content.scrollTop = content.scrollHeight;
    },
    removeForm(index) {
      this.fields.splice(index, 1);
      this.bufferConditions.splice(index, 1);
      this.bufferInputs.splice(index, 1);
      this.bufferConditionSelects.splice(index, 1);
    },
    async changeTypeForm(index) {
      if (this.fields[index].field_type_id == 1) {
        this.bufferInputs[index] = " ";
        this.bufferConditions[index] = [];
        this.bufferConditionSelects[index] = [0, 0];
        return true;
      } else if (this.fields[index].field_type_id == 2) {
        this.fields[index].field_choices = [
          { name: "Yes", sort_order: 0 },
          { name: "No", sort_order: 1 },
          { name: "Other", sort_order: 2 },
        ];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        return true;
      } else if (this.fields[index].field_type_id == 3) {
        this.bufferMultipleChoice[index] = { selected: 0 };
        this.fields[index].field_choices = [];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        return true;
      } else if (this.fields[index].field_type_id == 4) {
        this.fields[index].field_choices = [];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        return true;
      } else if (this.fields[index].field_type_id == 5) {
        this.fields[index].field_choices = [{ name: "" }];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        return true;
      } else if (this.fields[index].field_type_id == 6) {
        this.fields[index].field_choices = [];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        let idBranch = 0;
        if (this.fields[index].branch_group_id) {
          idBranch = this.fields[index].branch_group_id;
        }
        this.isLoading = true;
        await this.getBranchList(this.fields[index], idBranch);
      } else if (this.fields[index].field_type_id == 7) {
        this.fields[index].field_choices = [{ name: "" }];
        this.fields[index].field_conditions = [];
        this.bufferConditions[index] = [];
        return true;
      }
      return false;
    },
    async showPreview() {
      await this.fields.forEach((field, index) => {
        this.fields[index].field_conditions = this.bufferConditions[index];
      });
      this.form.fields = this.fields;
      this.previewForm = this.form;
      await this.$bvModal.show("bv-modal-showForm");
    },
    addValueInFirstCondition(index) {
      try {
        // type
        if (
          this.bufferConditionSelects[index][0] == 1 ||
          this.bufferConditionSelects[index][1] == 2
        ) {
          this.bufferConditions[index][0] = {
            field_condition_type_id: 1,
            value: "number",
          };
          this.bufferConditionSelects[index][1] = 0;
        }
        if (this.bufferConditionSelects[index][0] == 2) {
          this.bufferConditions[index][0] = {
            field_condition_type_id: 1,
            value: "text",
          };
          this.bufferConditions[index][1] = {
            field_condition_type_id: 2,
            value: "",
          };
          this.bufferConditions[index][2] = {
            field_condition_type_id: 4,
            value: "",
          };
          this.bufferConditionSelects[index][1] = 1;
        }
        this.fields[index].field_conditions = this.bufferConditions[index];
        // console.log("this.bufferConditions",this.bufferConditions[index])
        // console.log("this.fields[index].field_conditions" , this.fields[index].field_conditions)
        // this.resetSubCondition(index);
      } catch (error) {
        console.log(error);
      }
    },
    setchoiceInMuit(index) {
      if (this.bufferMultipleChoice[index].selected == 0) {
        this.fields[index].field_choices = [];
      } else if (this.bufferMultipleChoice[index].selected == 1) {
        this.fields[index].field_choices = [
          { name: "S" },
          { name: "M" },
          { name: "L" },
          { name: "XL" },
          { name: "XXL" },
        ];
      } else if (this.bufferMultipleChoice[index].selected == 2) {
        this.fields[index].field_choices = [
          { name: "1" },
          { name: "2" },
          { name: "3" },
          { name: "4" },
          { name: "5" },
        ];
      }
    },
    removeChoice(indexForm, indexChoice) {
      this.fields[indexForm].field_choices.splice(indexChoice, 1);
    },
    addValueInSetCondition(index) {
      try {
        if (this.bufferConditionSelects[index][1] == 1) {
          this.bufferConditions[index][1] = {
            field_condition_type_id: 2,
            value: "",
          };
          this.bufferConditions[index][2] = {
            field_condition_type_id: 4,
            value: "",
          };
        }
        // min-max
        if (this.bufferConditionSelects[index][1] == 2) {
          this.bufferConditions[index][1] = {
            field_condition_type_id: 3,
            value: "",
          };
          this.bufferConditions[index][2] = {
            field_condition_type_id: 4,
            value: "",
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    addChoice(indexForm) {
      this.fields[indexForm].field_choices.push({
        name: this.bufferInputs[indexForm],
        sort_order: this.fields[indexForm].field_choices.length,
      });
      this.bufferInputs[indexForm] = "";
    },
    addMultipleChoiceToBuffer() {
      this.fields.forEach((element, index) => {
        if (element.field_type_id == 3) {
          element.field_choices.forEach((ele) => {
            if (ele.name == "S") {
              this.bufferMultipleChoice[index].selected = 1;
            } else if (ele.name == "1") {
              this.bufferMultipleChoice[index].selected = 2;
            } else {
              this.bufferMultipleChoice[index].selected = 0;
            }
          });
        }
      });
    },
    resetSubCondition(index) {
      if (this.bufferConditionSelects[index][0] == 0) {
        this.bufferConditionSelects[index][1] = 0;
        this.bufferConditions[index][0] = {
          field_condition_type_id: 1,
          value: "",
        };
        this.bufferConditions[index][1] = {
          field_condition_type_id: 3,
          value: "",
        };
        this.bufferConditions[index][2] = {
          field_condition_type_id: 4,
          value: "",
        };
      }
      if (this.bufferConditionSelects[index][0] == 1) {
        this.bufferConditionSelects[index][1] = 0;
        this.bufferConditions[index][1] = {
          field_condition_type_id: 2,
          value: "",
        };
        this.bufferConditions[index][2] = {
          field_condition_type_id: 4,
          value: "",
        };
      }
    },
    chkDisabled(field, index) {
      if (this.id === "0") {
        if (!field.field_add_new && field.field_add_new == undefined) {
          if (field.required && field.is_profile) {
            let chkRequired = this.profileType.some(
              (el) => el.required === true
            );
            if (chkRequired) {
              return true;
            } else {
              return false;
            }
            // return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (!field.field_add_new && field.field_add_new == undefined) {
          if (field.required && field.is_profile && field.is_profile_required) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    updateImageList(list) {
      this.form.imageList = list;
    },
    deleteImgList(id) {
      this.deleteImageList.push(id);
    },
    addBufferConditionMax(index, value, field) {
      if (parseInt(this.id) > 0) {
        const filterTypeMax = field.field_conditions.findIndex(
          (el) => el.field_condition_type_id === 4
        );
        this.fields[index].field_conditions[filterTypeMax].value = value;
      }
    },
    addBufferConditionMin(index, value, field) {
      if (parseInt(this.id) > 0) {
        const filterTypeMax = field.field_conditions.findIndex(
          (el) => el.field_condition_type_id === 3
        );
        this.fields[index].field_conditions[filterTypeMax].value = value;
      }
    },
    addBufferConditionLength(index, value, field) {
      if (parseInt(this.id) > 0) {
        const filterTypeMax = field.field_conditions.findIndex(
          (el) => el.field_condition_type_id === 2
        );
        this.fields[index].field_conditions[filterTypeMax].value = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}
.pink-whitebutton {
  color: #fff;
  border-color: var(--primary-color);
  background-image: linear-gradient(var(--primary-color), var(--primary-color));
}
.base-button.red-font {
  color: var(--primary-color);
}
.btn-height {
  height: 50px;
}
.card-duplicate-error {
  border-color: var(--primary-color);
}
.title-fields {
  font-weight: 600;
  font-size: 16px;
}
.position-checkbox {
  position: absolute;
  right: 0;
}
.tab-title {
  background-color: var(--secondary-color);
  padding: 10px 0;
  font-weight: 600;
  color: #000;
  margin: 0;
}
.title-underline {
  text-decoration: underline;
}
.btn-add-question {
  color: var(--primary-color) !important;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}
::v-deep .custom-control {
  display: inline-block;
}
.icon-size {
  padding: 0;
  top: 5px;
  font-size: 20px;
}
</style>
