<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-if="!isLoading">
      <b-row class="mt-3 px-1 px-sm-3">
        <b-col>
          <b-input-group class="search-list">
            <b-form-input
              type="text"
              class="search-bar"
              placeholder="Member ID, Name-Surname"
              v-model="filter.search"
              @keyup.enter="submitFilter"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-1">
              <b-iconstack font-scale="2" @click.prevent="submitFilter">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-b-toggle.sidebar-filter
            class="btn button btn-mobile campaign-filter mx-2"
            id="filterBtn"
          >
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="main-color mr-0 mr-sm-1 pointer"
            />
            <span class="d-none d-sm-inline">Filter </span>
          </b-button>
          <button
            type="button"
            class="btn button btn-mobile ml-2"
            @click.prevent="exportExcel"
          >
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-col>
      </b-row>
      <b-sidebar
        id="sidebar-filter"
        v-model="sidebarShow"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
        aria-labelledby="sidebar-filter-title"
        no-header
      >
        <template #default="{ hide }">
          <div class="px-3">
            <b-row class="header-filter">
              <b-col class="text-filter"> Filter </b-col>
              <b-col class="text-right">
                <button class="btn-clear" @click="clearFilter">
                  x <span class="text-under">Clear Filter</span>
                </button>
              </b-col>
            </b-row>
            <div>
              <div class="my-2">
                <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
                <b-form-input
                  type="text"
                  placeholder="Search Member ID , Name-Surname"
                  class="input-container search-filter"
                  v-model="filter.search"
                  @keypress.enter="submitFilter"
                ></b-form-input>
              </div>
              <div class="">
                <div class="mt-2">
                  <div class="font-weight-bold mb-2">Date Filter</div>
                  <div class="input-container">
                    <datetime
                      id="date"
                      :input-style="styleDatetime"
                      v-model="filter.date"
                      placeholder="DD/MM/YYYY"
                      format="dd/MM/yyyy"
                      value-zone="Asia/Bangkok"
                      ref="filterDate"
                    >
                    </datetime>
                    <div
                      id="startDateIcon"
                      class="icon-container text-right"
                      @click="$refs.filterDate.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        class="pointer color-primary"
                        color="var(--primary-color)"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <b-form-group>
                    <template #label>
                      <div class="font-weight-bold">Status</div>
                      <b-form-checkbox
                        v-model="statusAll"
                        aria-describedby="status"
                        aria-controls="status"
                        @change="selectAllStatus"
                      >
                        All
                      </b-form-checkbox>
                    </template>
                    <template v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        id="status"
                        v-model="filter.status_list"
                        :options="StatusOptions"
                        :aria-describedby="ariaDescribedby"
                        name="status"
                        class="ml-4"
                        value-field="id"
                        text-field="name"
                        aria-label="Individual status"
                        stacked
                      ></b-form-checkbox-group>
                    </template>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div
            class="d-flex justify-content-between shadow align-items-center filter-btn"
          >
            <div class="w-100">
              <b-button
                block
                variant="dark"
                class="text-light btn-block"
                @click.prevent="hide"
              >
                Cancel
              </b-button>
            </div>
            <div class="w-100">
              <b-button
                @click.prevent="submitFilter"
                block
                variant="light"
                id="handle-search-filter"
                class="text-body btn-block btn-main"
              >
                Search
              </b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
      <b-row class="mt-3 mt-3 px-1 px-sm-3">
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="displayItems"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(customerDetail)="data">
              <div class="pl-2 text-left">
                <b-button variant="link" class="px-0 py-0">
                  <router-link
                    :to="`/report/customer/${data.item.user_guid}`"
                    class="text-purple-1"
                  >
                    <u>{{ data.item.name }} {{ data.item.lastname }}</u>
                  </router-link>
                </b-button>
                <p class="mb-0 text-gray">{{ data.item.phone }}</p>
                <p class="mb-0 text-gray">{{ data.item.email }}</p>
              </div>
            </template>
            <template v-slot:cell(customField1)="data">
              <div>
                <a
                  v-if="data.item.customField1Type == 8"
                  :href="data.item.customField1"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField1 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField1 }}</div>
              </div>
            </template>
            <template v-slot:cell(customField2)="data">
              <div>
                <a
                  v-if="data.item.customField2Type == 8"
                  :href="data.item.customField2"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField2 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField2 }}</div>
              </div>
            </template>
            <template v-slot:cell(customField3)="data">
              <div>
                <a
                  v-if="data.item.customField3Type == 8"
                  :href="data.item.customField3"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField3 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField3 }}</div>
              </div>
            </template>
            <template v-slot:cell(created_time)="data">
              <div class="d-flex justify-content-center">
                {{
                  $moment(data.item.created_time).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                }}
              </div>
            </template>
            <template v-slot:cell(is_consent)="data">
              <div>
                <span
                  :class="
                    data.item.is_consent == 2
                      ? 'text-purple'
                      : data.item.is_consent == 1
                      ? 'text-success'
                      : 'text-danger'
                  "
                  >{{
                    data.item.is_consent == 2
                      ? "waiting for consent"
                      : data.item.is_consent == 1
                      ? "Approve"
                      : "Disapprove"
                  }}</span
                >
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div>
                <span
                  :class="
                    data.item.status == 2
                      ? 'text-danger'
                      : data.item.status == 1
                      ? 'text-success'
                      : 'text-purple'
                  "
                  >{{
                    data.item.status == 2
                      ? "Reject"
                      : data.item.status == 1
                      ? "Approve"
                      : "Pending"
                  }}</span
                >
              </div>
            </template>
            <template v-slot:cell(manage)="data">
              <div>
                <span
                  class="mb-0 manage-btn"
                  @click="editCanpaign(data.item.id)"
                  >Detail</span
                ><br />
                <span
                  :class="['mb-0 manage-btn', { error: data.item.status != 0 }]"
                  @click="openMessageModal(data.item)"
                  >Reject</span
                ><br />
                <span
                  :class="['mb-0 manage-btn', { error: data.item.status != 0 }]"
                  @click="updateCanpaign(data.item, 2)"
                  >Done</span
                >
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="pb-3 mt-3 px-1 px-sm-3">
        <b-col cols="12" md="6" class="form-inline justify-content-start">
          <p class="mb-0 p-gray">
            Showing {{ rows > 0 ? (filter.page - 1) * filter.take + 1 : 0 }} to
            {{
              filter.page * filter.take > rows
                ? rows
                : filter.page * filter.take
            }}
            of {{ rows }} entires
          </p>
        </b-col>
        <b-col cols="12" md="6" class="form-inline justify-content-end">
          <b-pagination
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.take"
            @change="pagination"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            class="m-md-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-model="modalMessageShow"
      title="Product List"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      header-class="bg-purple1"
    >
      <template #modal-header>
        <div class="w-100">
          <strong class="title-modal white-color">Submit/Reject</strong>
          <b-icon
            icon="x-circle-fill"
            class="float-right pointer white-color"
            @click="modalMessageShow = false"
          ></b-icon>
        </div>
      </template>
      <div>
        <label>Additional Reasons (if any)</label>
        <InputTextArea
          textFloat=""
          type="text"
          v-model="message"
          class="mt-0 custom-input"
          placeholder="Provide Additional Information"
          rows="3"
          name="message"
        />
        <b-row>
          <b-col sm="6">
            <b-button class="cancel-btn" @click="cancelMessageModel">
              Cancel
            </b-button>
          </b-col>
          <b-col sm="6" class="d-flex justify-content-end">
            <b-button class="approve-btn" @click="submitMessageModel">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    InputTextArea,
    ModalAlertError,
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: Number,
    },
    name: {
      required: false,
    },
  },
  data() {
    return {
      statusAll: true,
      sidebarShow: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      modalMessage: "",
      modalMessageShow: false,
      detailSelect: {},
      message: "",
      isLoading: false,
      StatusOptions: [
        {
          id: 2,
          name: "Pending",
        },
         {
          id: 1,
          name: "Approve",
        },
         {
          id: 0,
          name: "Reject",
        },
         {
          id: 6,
          name: "Recall",
        },
        {
          id: 7,
          name: "Suspend",
        }
      ]
    };
  },
  watch: {
    "filter.status_list"(newValue) {
      if (newValue.length === 0) {
        this.statusAll = false;
      } else if (newValue.length === this.StatusOptions.length) {
        this.statusAll = true;
      } else {
        this.statusAll = false;
      }
    },
  },
  computed: {
    displayItems() {
      for (const item of this.items) {
        for (const fieldName in item) {
          const field = item[fieldName];
          if (!field) continue;
          if (Array.isArray(field)) {
            let dataToString = "";
            for (const item of field) {
              dataToString = dataToString + ", " + item;
            }
            field = dataToString.slice(2);
          }
          item[fieldName] = field;
        }
      }
      return this.items;
    },
  },
  methods: {
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.$emit("getListUser", this.filter);
    },
    async exportExcel() {
      this.isLoading = true;
      await this.$store.dispatch("exportCampaignForm", this.id);
      let data = this.$store.state.form.respExport;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `Campaign-${this.name}-` + dateExcel + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.isLoading = false;
    },
    editCanpaign(id) {
      this.$router.push({
        path: `/campaign/detail/${id}?campaignID=${this.$route.params.id}&formID=${this.id}`,
      });
    },
    async updateCanpaign(detail, status) {
      console.log("2");
      if (detail.status == 0) {
        let body = {
          is_accept: status,
          user_id: detail.id,
          point: 0,
          note: "",
          telephone: detail.phone,
          message: this.message,
          user_fill: [],
        };
        this.isLoading = true;
        await this.$store.dispatch("updateCampaign", body);
        let data = this.$store.state.form.respUpdateCampaign;
        if (data.result == 1) {
          this.isLoading = false;
          this.$swal("Create Success!", {
            icon: "success",
          }).then(
            function () {
              this.clearFilter();
            }.bind(this)
          );
        } else {
          this.isLoading = false;
          this.$refs.modalAlertError.show();
          this.modalMessage = data.message;
        }
      }
    },
    selectAllStatus(value) {
      if (value) {
        this.filter.status_list = [2, 1, 0, 6, 7];
      } else {
        this.filter.status_list = [];
      }
    },
    clearFilter() {
      this.filter = {
        page: 1,
        take: 10,
        search: "",
        date: "",
        status_list: [2, 1, 0, 6, 7],
      };
    },
    hide () {
      this.sidebarShow = false
    },
    submitFilter() {
      this.filter.page = 1;
      this.filter.take = 10;
      this.$emit("getListUser", this.filter);
    },
    openMessageModal(detail) {
      if (detail.status == 0) {
        this.detailSelect = detail;
        this.modalMessageShow = true;
      }
    },
    cancelMessageModel() {
      this.message = "";
      this.detailSelect = {};
      this.modalMessageShow = false;
    },
    submitMessageModel() {
      this.modalMessageShow = false;
      this.updateCanpaign(this.detailSelect, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.search-list {
  width: 350px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
.text-filter {
  font-weight: 600;
}
.input-container {
  display: flex;
  padding: 0.375rem 0.75rem;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.filter-btn .btn {
  border-radius: 0 !important;
}
.campaign-filter {
  width: 120px;
}

.text-gray {
  color: #707070;
}
.text-purple {
  color: var(--primary-color);
}
.manage-btn {
  cursor: pointer;
  color: #4f5d73;
}
.manage-btn:hover {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;
}
.manage-btn.error {
  cursor: context-menu;
  color: #a1a1a1;
}
.manage-btn.error:hover {
  cursor: context-menu;
  color: #a1a1a1;
  text-decoration: none;
}
.approve-btn {
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.approve-btn:hover {
  color: var(--primary-color);
  background-color: #fff;
}
.cancel-btn {
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.cancel-btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}
.text-score {
  color: var(--primary-color);
  font-size: 42px;
}
</style>
