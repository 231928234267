<template>
  <div class="w-50">
    <b-form-input v-if="!condition.typeId.length"></b-form-input>
    <div v-if="condition.typeId.length == 1">
      <b-form-input v-if="this.condition.typeId[0] == 0"></b-form-input>
      <!-- type -->
      <b-form-input
        v-if="this.condition.typeId[0] == 1"
        :type="condition.valuetype"
      ></b-form-input>
      <!-- limit -->
      <b-form-input
        v-if="this.condition.typeId[0] == 2"
        :formatter="limitInputs"
      ></b-form-input>
    </div>

    <div v-if="condition.typeId.length == 2">
      <!-- type && limit -->
      <b-form-input
        :type="condition.valuetype"
        :formatter="limitInputs"
      ></b-form-input>
    </div>
    <div v-if="condition.typeId.length == 3">
      <!-- type && limit -->
      <b-form-input
        v-model="inputValue"
        :type="condition.valuetype"
        :min="condition.min"
        :max="condition.max"
        @keyup.enter="checkInputValue()"
      ></b-form-input>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputPreview",
  props: {
    conditions: Array,
  },
  data() {
    return {
      condition: {
        typeId: [],
        valuetype: "text",
        limitinput: null,
        min: null,
        max: null,
      },
      inputValue: null,
    };
  },
  created() {
    this.$props.conditions.forEach((element) => {
      this.condition.typeId.push(element.field_condition_type_id);
      if (element.field_condition_type_id == 1) {
        if (element.value != "") {
          this.condition.valuetype = element.value;
        } else {
          this.condition.typeId.pop();
        }
      }
      if (element.field_condition_type_id == 2) {
        if (element.value != "") {
          this.condition.limitinput = parseInt(element.value);
        } else {
          this.condition.typeId.pop();
        }
      }
      if (element.field_condition_type_id == 3) {
        if (element.value != "") {
          this.condition.min = parseInt(element.value);
        } else {
          this.condition.typeId.pop();
        }
      }
      if (element.field_condition_type_id == 4) {
        if (element.value != "") {
          this.condition.max = parseInt(element.value);
        } else {
          this.condition.typeId.pop();
        }
      }
    });
  },
  methods: {
    checkInputValue() {
      setTimeout(() => {
        if (this.inputValue == "") {
          this.inputValue = " ";
        }
        if (this.inputValue != null) {
          if (this.inputValue <= this.condition.min) {
            this.inputValue = this.condition.min;
            return "";
          } else if (this.inputValue >= this.condition.max) {
            this.inputValue = this.condition.max;
            return "";
          }
        }
      }, 1800);
    },
    limitInputs(e) {
      return String(e).substring(0, this.condition.limitinput);
    },
  },
};
</script>