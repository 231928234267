<template>
  <div>
    <b-container fluid>
      <div v-if="$props.state">
        <b-card
          title
          header-tag="header"
          footer-tag="footer"
          class="scroll-preview"
        >
          <template v-slot:header>
            <b-row>
              <b-col>
                <h3 class="mt-2 head-card">
                  <!-- <b>{{ $props.form }}</b> -->
                </h3>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-button
                  class="base-button"
                  size="lg"
                  @click="$emit(`isPreview`, !$props.isPreview)"
                >
                </b-button>
              </b-col>
            </b-row>
          </template>
          <!-- <p>{{ $props.form }}</p> -->
          <b-card>
            <b-card-body>
              $props.form.fields :: {{ $props.form.fields }}
              <div v-for="(field, index) in $props.form.fields" :key="index">
                <h4 v-if="field.name != ''" class="mb-2">
                  <b>{{ field.name }}</b>
                </h4>
                <h4 v-if="field.name == ''" class="mb-2">
                  <b>no data</b>
                </h4>
                <!-- Field -->
                <div v-if="field.field_type_id == 1">
                  <!-- field.fieldConditions.length{{field.fieldConditions.length}} -->
                  <input-preview
                    class="mb-2"
                    style="width: 54% !important"
                    :conditions="field.field_conditions"
                  ></input-preview>
                </div>
                <!-- Checkbox -->
                <div v-if="field.field_type_id == 2">
                  <div
                    v-for="(choice, index) in field.field_choices"
                    :key="index"
                  >
                    <p>
                      <b-form-checkbox
                        class="mt-auto mb-auto"
                        :value="choice.name"
                        v-model="choice.value"
                        >{{ choice.name }}</b-form-checkbox
                      >
                    </p>
                  </div>
                </div>
                <!-- Multiple Choice -->
                <div v-if="field.field_type_id == 3">
                  <p>
                    <b-form-group
                      v-for="(choice, index) in field.field_choices"
                      :key="index"
                    >
                      <b-form-radio
                        v-model="field.value"
                        :value="choice.name"
                        >{{ choice.name }}</b-form-radio
                      >
                    </b-form-group>
                  </p>
                </div>
                <!-- Dropdown -->
                <div v-if="field.field_type_id == 4">
                  <p>
                    <b-form-select
                      style="
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        width: 54% !important;
                      "
                      v-model="field.value"
                      :options="field.field_choices"
                      value-field="name"
                      text-field="name"
                    ></b-form-select>
                  </p>
                </div>
                <!--  Date -->
                <div v-if="field.field_type_id == 8">
                  <b-row
                    v-for="(choice, index) in field.field_choices"
                    :key="index"
                  >
                    <b-col>
                      <section class="form-date-field">
                        <div class="d-input inner-addon right-addon">
                          <b-icon
                            class="glyphicon"
                            icon="calendar4-week"
                          ></b-icon>
                          <datetime
                            type="datetime"
                            v-model="choice.name"
                          ></datetime>
                        </div>
                      </section>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-card>
      </div>
      <div v-else>
        <b-card class="mb-2 header">
          <template>
            <div class="d-flex justify-content-between">
              <h5 class="mt-2">
                <b style="text-transform: uppercase">{{ $props.form.name }}</b>
              </h5>
              <img v-if="!isRequired" v-b-toggle="`collapse-field`" />
            </div>
          </template>
          <hr />
          <b-card-text>{{ $props.form.description }}</b-card-text>
        </b-card>

        <div v-for="(field, index) in $props.form.fields" :key="index">
          <b-collapse :id="`collapse-field`" v-if="!isRequired">
            <!-- input -->
            <b-card class="mb-2" v-if="field.field_type_id == 1">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <!-- no required -->
                <b-collapse :id="`collapse-field`" v-if="!isRequired">
                  <input-preview
                    class="mb-2"
                    :conditions="field.field_conditions"
                  ></input-preview>
                </b-collapse>
              </div>
            </b-card>

            <!-- checkbox -->
            <b-card style="margin-bottom: 9px" v-if="field.field_type_id == 2">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <!-- no required -->
                <b-collapse :id="`collapse-field`" v-if="!isRequired">
                  <b-form-checkbox-group
                    v-model="field.value"
                    :options="field.field_choices"
                    value-field="name"
                    text-field="name"
                    stacked
                  ></b-form-checkbox-group>
                </b-collapse>
              </div>
            </b-card>

            <!-- Muti -->
            <b-card
              class="mb-2"
              style="margin-bottom: 9px"
              v-if="field.field_type_id == 3"
            >
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <!-- no required -->
                <b-collapse :id="`collapse-field`" v-if="!isRequired">
                  <b-form-radio-group
                    :options="field.field_choices"
                    :required="field.required"
                    v-model="field.value"
                    value-field="name"
                    text-field="name"
                    stacked
                  ></b-form-radio-group>
                </b-collapse>
              </div>
            </b-card>

            <!-- Dropdown -->
            <b-card class="mb-2" v-if="field.field_type_id == 4">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <!-- no required -->
                <b-collapse :id="`collapse-field`" v-if="!isRequired">
                  <b-form-select
                    class="w-50"
                    :options="field.field_choices"
                    :required="field.required"
                    v-model="field.value"
                    value-field="name"
                    text-field="name"
                  ></b-form-select>
                </b-collapse>
              </div>
            </b-card>

            <!-- DateTime -->
            <b-card class="mb-2" v-if="field.field_type_id == 8">
              <div v-for="(choice, index) in field.field_choices" :key="index">
                <!-- <b-form-group> -->
                <div class="d-flex align-items-center">
                  <b-row class="w-100 mt-2">
                    <b-col>
                      <label>
                        <b>{{ field.name }}</b>
                      </label>
                    </b-col>
                  </b-row>
                </div>
                <div class="w-100 mt-2 md-2">
                  <!-- no required -->
                  <b-collapse :id="`collapse-field`" v-if="!isRequired">
                    <DateInput />
                  </b-collapse>
                </div>
              </div>
            </b-card>
          </b-collapse>
          <div v-if="isRequired">
            <!-- input -->
            <b-card class="mb-2" v-if="field.field_type_id == 1">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <div>
                  <InputPreview
                    @callBack="fieldInput($event, index)"
                    :conditions="field.field_choices"
                    :required="field.required"
                    :isOnDetail="true"
                  ></InputPreview>
                </div>
              </div>
            </b-card>

            <!-- checkbox -->
            <b-card style="margin-bottom: 9px" v-if="field.field_type_id == 2">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <b-form-checkbox-group
                  v-model="field.value"
                  :options="field.field_choices"
                  value-field="name"
                  text-field="name"
                  :required="checkRequestCheck(field.value)"
                  stacked
                ></b-form-checkbox-group>
              </div>
            </b-card>

            <!-- Muti -->
            <b-card
              class="mb-2"
              style="margin-bottom: 9px"
              v-if="field.fieldTypeId == 3"
            >
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <b-form-radio-group
                  :options="field.field_choices"
                  :required="field.required"
                  v-model="field.value"
                  value-field="name"
                  text-field="name"
                  stacked
                ></b-form-radio-group>
              </div>
            </b-card>

            <!-- Dropdown -->
            <b-card class="mb-2" v-if="field.field_type_id == 4">
              <!-- <b-form-group> -->
              <div class="d-flex align-items-center">
                <b-row class="w-100 mt-2">
                  <b-col>
                    <label>
                      <b>{{ field.name }}</b>
                    </label>
                  </b-col>
                </b-row>
              </div>
              <div class="w-100 mt-2 md-2">
                <b-form-select
                  style="-webkit-appearance: none; -moz-appearance: none"
                  class="w-50"
                  :options="field.field_choices"
                  :required="field.required"
                  v-model="field.value"
                  value-field="name"
                  text-field="name"
                ></b-form-select>
              </div>
            </b-card>

            <!-- DateTime -->
            <b-card class="mb-2" v-if="field.field_type_id == 5">
              <div v-for="(choice, index) in field.field_choices" :key="index">
                <!-- <b-form-group> -->
                <div class="d-flex align-items-center">
                  <b-row class="w-100 mt-2">
                    <b-col>
                      <label>
                        <b>{{ field.name }}</b>
                      </label>
                    </b-col>
                  </b-row>
                </div>
                <div class="w-100 mt-2 md-2">
                  <DateInput />
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import InputPreview from "@/components/form/preview/InputPreview";
import DateInput from "@/components/form/preview/DateInput";
export default {
  name: "Preview",
  components: {
    InputPreview,
    DateInput,
  },
  props: {
    form: Object,
    state: Boolean,
    isPreview: Boolean,
  },
  data() {
    return {
      isRequired: true,
    };
  },
  created() {
    this.$props.form.fields.forEach((field) => {
      if (!field.required) {
        this.isRequired = false;
      }
    });
  },
  methods: {
    checkRequestCheck(data) {
      if (data != null) {
        let values = data;
        if (values) {
          if (values.length > 0) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.scroll-preview {
  min-height: 20vh;
  max-height: 100vh;
  overflow-y: auto;
}
.d-input {
  display: table-cell;
  width: 100%;
}

.input-t {
  border-color: transparent !important;
}

.inner-addon {
  position: relative;
}

.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

.right-addon .glyphicon {
  top: 2.5px;
}

.right-addon input {
  padding-right: 30px;
}
</style>